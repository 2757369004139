import React from "react"
import InstallButton from "@components/marketing/installButton"

const CTA = () => (
    <div className="px-4 sm:px-6 py-10 sm:py-16 md:py-20 lg:py-24">
        <div className="bg-gray-100 mx-auto rounded-xl max-w-4xl px-6 py-8 md:px-12 sm:py-16">
            <h2 className="text-lg sm:text-3xl md:text-4xl font-bold text-gray-900 max-w-4xl mx-auto mb-3 sm:mb-4 text-center">
                Start your free trial today.
            </h2>
            <p className="px-2 sm:px-0 text-sm sm:text-xl text-gray-900 max-w-3xl mx-auto mt-0 mb-6 sm:mt-2 sm:mb-8 text-center">
                Sheetsync is only $59 / year. That's just $4.92 / month.
            </p>
            <div className="max-w-md mx-auto mt-4 flex justify-center">
                <InstallButton />
            </div>
        </div>
    </div>
)

export default CTA
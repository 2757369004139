import * as React from "react"
import Logomark from "@images/logos/sheetsync-logomark.svg"
import { Link } from "gatsby"

const Footer = () => (
    <div className="bg-white basier">
        <div className="max-w-6xl mx-auto py-8 sm:py-12 px-6 sm:px-6 lg:py-16 lg:px-8 lg:pb-8">
            <div className="max-w-6xl lg:grid lg:grid-cols-3 lg:gap-8">
                <div className="lg:col-span-1">
                    <img className="h-8" src={Logomark} alt="Sheetsync" />
                    <p className="mt-3 text-gray-500 text-xs leading-6">
                        Built in College Station, Texas.
                    </p>
                </div>
                <div className="mt-2 md:mt-12 grid grid-cols-2 gap-8 lg:mt-0 lg:col-span-2">
                    <div className="sm:grid sm:grid-cols-2 sm:gap-2 md:gap-8">
                        <div className="mt-6 md:mt-0">
                            <h4 className="text-xs leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                                Product
                            </h4>
                            <ul className="mt-2 sm:mt-3">
                                <li>
                                    <Link to="/pricing" className="text-sm leading-6 text-gray-500 hover:text-gray-900">
                                        Pricing
                                    </Link>
                                </li>
                                <li className="mt-1">
                                    <Link to="/privacy-and-security" className="text-sm leading-6 text-gray-500 hover:text-gray-900">
                                        Privacy & Security
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-6 md:mt-0">
                            <h4 className="text-xs leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                                Resources
                            </h4>
                            <ul className="mt-2 sm:mt-3">
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://help.getsheetsync.com" className="text-sm leading-6 text-gray-500 hover:text-gray-900">
                                        Help Center
                                    </a>
                                </li>
                                <li className="mt-1">
                                    <Link to="/affiliates" className="text-sm leading-6 text-gray-500 hover:text-gray-900">
                                        Affiliates
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-2 sm:gap-2 md:gap-8">

                        <div className="mt-6 md:mt-0">
                            <h4 className="text-xs leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                                Company
                            </h4>
                            <ul className="mt-2 sm:mt-3">
                                <li>
                                    <Link to="/about" className="text-sm leading-6 text-gray-500 hover:text-gray-900">
                                        About
                                    </Link>
                                </li>
                                <li className="mt-1">
                                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sheetsync" className="text-sm leading-6 text-gray-500 hover:text-gray-900">
                                        Twitter
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-6 md:mt-0">
                            <h4 className="text-xs leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                                Legal
                            </h4>
                            <ul className="mt-2 sm:mt-3">
                                <li>
                                    <Link to="/privacy" className="text-sm leading-6 text-gray-500 hover:text-gray-900">
                                        Privacy
                                    </Link>
                                </li>
                                <li className="mt-1">
                                    <Link to="/terms" className="text-sm leading-6 text-gray-500 hover:text-gray-900">
                                        Terms
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-12 border-t border-gray-200 pt-4 sm:pt-8">
                <p className="text-xs sm:text-sm leading-6 text-gray-400 text-center">
                    &copy; 2020 Sheetsync, LLC. All rights reserved.
                </p>
            </div>
        </div>
    </div>
)

export default Footer
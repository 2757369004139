import React from "react"

const SignInButton = () => (
  <div className="block">
    <div className="rounded-md shadow">
      <a
        href="https://gsuite.google.com/marketplace/app/sheetsync/198068442022"
        target="_blank" rel="noopener noreferrer"
        className="basis w-full flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-3 md:text-md md:px-6"
      >
        <span className="system">Install on Google Sheets</span>
      </a>
    </div>
    <div className="text-xs text-gray-500 text-center mt-2 flex float-left">
      <div className="flex-shrink-0 inline-flex">
        <svg className="h-4 w-4 mr-1 mt-0.25 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
      </div>
      14 day free trial
        </div>
    <div className="text-xs text-gray-500 text-center mt-2 flex float-right">
      <div className="flex-shrink-0 inline-flex">
        <svg className="h-4 w-4 mr-1 mt-0.25 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
      </div>
      Cancel anytime
        </div>
  </div >
)

export default SignInButton
